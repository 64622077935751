import React from "react";
import styled from "styled-components";
import { Font, Color, media, rem, rgba } from "../utils/style";
import { Icons } from "../utils/svg";

const SelectWrapper = styled.div`
  position: relative;
  float: right;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;

  ${media.mobile`
    padding: 0 15px;
  `};
`;

const DropDownWrapper = styled.div`
  position: relative;
  width: 100%;
  float: right;

  svg {
    position: absolute;
    right: 16px;
    top: 20px;
    width: 14px;
  }
`;

const StyledSelect = styled.select`
  ${Font.circular}
  color: ${Color.ritualBlue};
  font-size: ${rem(16)};
  line-height: ${rem(22)};
  font-weight: 300;
  text-align: left;
  appearance: none;
  border: 1px solid ${Color.ritualSecondaryDisabledBlue};
  border-radius: 0;
  padding: 0 30px 0 1rem;
  height: 44px;
  outline: 0;
  background-color: ${Color.white};
  caret-color: ${Color.ritualBlue};
  width: 100%;

  &.product-selector {
    background-color: ${Color.white};

    optgroup {
      background-color: ${Color.white};
      background: ${Color.white};
      color: ${rgba(Color.ritualBlue, 0.56)};

      option {
        background-color: ${Color.white};
        color: ${Color.ritualBlue};
      }
    }
  }
`;

const DropdownLabel = styled.label`
  ${Font.circular}
  color: ${Color.ritualBlue};
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  font-weight: 300;
  text-align: left;
  letter-spacing: 0;
  margin-bottom: 8px;
  text-align: left;
  float: left;
  clear: both;
`;

const Dropdown = ({ children, ...props }) => {
  return (
    <SelectWrapper>
      {props.label && <DropdownLabel>{props.label}</DropdownLabel>}
      <DropDownWrapper>
        <Icons.CaretDown />
        <StyledSelect className={props.className} {...props}>
          {children}
        </StyledSelect>
      </DropDownWrapper>
    </SelectWrapper>
  );
};

export default Dropdown;
